import firebase from "firebase/compat/app";
import "firebase/compat/auth"; // for authentication
import "firebase/compat/firestore"; // for cloud firestore
import "firebase/compat/functions"; 

const firebaseConfig = {
  apiKey: "AIzaSyBEN3TQXXkswqbPpYZg_tVK0ORIN5z0Dio",
  authDomain: "sling-mobility-d5949.firebaseapp.com",
  databaseURL: "https://sling-mobility-d5949-default-rtdb.firebaseio.com",
  projectId: "sling-mobility-d5949",
  storageBucket: "sling-mobility-d5949.appspot.com",
  messagingSenderId: "779962716575",
  // appId: "1:779962716575:web:5a9cbc9c6c203c4f976daa",
  appId: "1:779962716575:web:354e150763fcf476976daa",
  // measurementId: "G-JNY1RSM6VW"
  measurementId: "G-XX59D4CV3D",
};


firebase.initializeApp(firebaseConfig);

export default firebase;
