import "./termsAndConditions.css";

function TermsAndConditions() {
  // const navigate = useNavigate();

  return (
    <>
      <div className="background-color">
        <header className="header-logo">
          <div className="logo">
            <img
              src="/images/logo/Sling Logo color.png"
              alt="Logo"
              className="logo-image"
            />
          </div>
        </header>
        <div className="terms-container">
          <h1 className="terms-heading">
            Battery Subscription and Service Agreement
          </h1>
          <p className="effective-date">
            This Battery Subscription and Service Agreement (hereinafter
            referred to as "this Agreement") is signed or agreed by the user
            (defined in clause 1) and Sling Mobility (Private) Limited
            (hereinafter referred to as "Sling MObility"). By Signing or
            agreeing to subscribe (including but not limited to purchse order,
            etc., hereinafter collectively referred to as "subscription
            document"), users subscribe to use the battery as a service provided
            by Sling Mobility for the powering of the Electric Vehicles owned/or
            leased by the user and through the use of the Sling Mobility Battery
            Swapping Stations and infrastructure in accordance with the terms in
            this Agreement.
          </p>
          <p className="effective-date">
            This Agreement is only applicable to the electric vehicle
            purchased/leashed by the user from Sling Mobility or Sling Mobility
            partners (referred to as "The Electric Vehicle").
          </p>

          <section className="terms-section">
            <h2>1. Users of battery service</h2>
            <p>
              The term 'User' in this Agreement pertains to the individual or
              entity that has acquired ownership of an Electric Vehicle and has
              duly registered the vehicle with the Department of Motor Traffic
              Sri Lanka. In the event that the User opts to transfer ownership
              of the Electric Vehicle to a third party, then the User to ensure
              that such transfer is subject to the third party subscribing to
              and consenting to abide by the terms stipulated in this Agreement,
              and/or any other pertinent agreements.
            </p>
          </section>

          <section className="terms-section">
            <h2>2. Battery Service Term</h2>
            <p>
              1. The Agreement shall apply for the duration of their
              subscription to the battery service from Sling Mobility. The
              activation date of the battery subscription shall be the date of
              signing or agreeing to the subscription document.
            </p>
            <p>
              2. If, upon the expiration or termination of this Agreement, the
              user fails to return all Sling Mobility Batteries to the
              designated location as specified by Sling Mobility, Sling Mobility
              reserves the right to assume that the user, by default, subscribes
              to the lowest basic cost tariff plan among those currently offered
              by Sling Mobility, or renews the last subscribed tariff plan in
              effect before the conclusion of the service term, unless the user
              has duly changed the tariff plan as outlined in Article 4, Item 2
              of this Agreement. The user's subscription for battery service
              operates on a 30 day period basis. However, in the event of a
              violation of this Agreement during the term, Sling Mobility
              retains the discretion to deny the user's continued subscription
              to the battery service.
            </p>
          </section>

          <section className="terms-section">
            <h2>3. Scope of battery service</h2>
            <p>
              1. During the term of this Agreement, Sling Mobility shall provide
              Batteries in sufficient quantity for the User or its authorized
              person to enable the operation of the Electric Vehicle.
            </p>
            <p>
              2. Users or their authorized persons may (1) go to any Battery
              Swapping Station operated by Sling Mobility any time during the
              operating hours to use and manipulate the battery service provided
              by Sling Mobility.
            </p>
          </section>

          <section className="terms-section">
            <h2>4. Service Fee</h2>
            <p>
              1. When signing or agreeing to the subscription document, the user
              shall choose one of the tariff plans provided by Sling Mobility at
              that time, and service fee will be calculated from the date when
              the service is activated as per the terms of Use of the Sling
              Mobility App.
            </p>
            <p>
              2. Unless otherwise agreed by both parties (for example, when the
              user agrees to the binding plan), the user may notify Sling
              Mobility at any time during the service term and change its tariff
              plan to others provided by Sling Mobility at the time. The revised
              tariff plan will be applied for the remaining service term from
              the next billing cycle after notification of the change. Sling
              Mobility reserves the right to suspend, cancel or change the
              existing tariff plan or its contents and add other tariff plans.
            </p>
            <p>
              3. Unless otherwise agreed by both parties, the user shall pay the
              basic fees and additional fees (hereinafter referred to as
              "service fees") according to the tariff plan purchased and the
              additional mileage or extra battery usage and pay according to the
              payment method and payment deadline stated in the invoice.
            </p>
            <p>
              4. The mileage (including the basic mileage and the additional
              mileage generated by the tariff plan) and / or battery usage for
              each billing cycle is calculated by (1) battery swapping at any
              Battery Swapping Station. The internal records of Sling Mobility
              are updated after any Batteries is swapped at the Battery Swapping
              Station. If the mileage and / or battery usage displayed on the
              Sling Mobility App is inconsistent with the internal records of
              Sling Mobility, the service fee shall be calculated based on the
              internal records of Sling Mobility.
            </p>
            <p>
              5. Unless the inconsistency between record of mileage or battery
              usage and the actual usage is due to any system errors of Sling
              Mobility, Sling Mobility has the right to charge user for service
              fees.
            </p>
            <p>
              6. The basic mileage of the tariff plan is limited to be used
              within the billing month. Unused mileage for that month will be
              rolled over to next month until 90 days from the date of purchase
              of the tariff plan. The rolled over mileage is usable only if the
              following month subscription is paid by the user.
            </p>
          </section>

          <section className="terms-section">
            <h2>5. Battery Ownership</h2>
            <p>
              User knows that Sling Mobility is the sole owner of the Battery.
              The user does not have ownership, other property rights,
              intellectual property rights or any other rights to the Battery.
              Except for the right to use the Battery under this Agreement, the
              user shall not claim or exercise ownership, lien, other property
              rights or any rights on the Battery, and the user shall not have
              the right to transfer any rights or interests of the Battery to
              any third party.
            </p>
          </section>

          <section className="terms-section">
            <h2>6. User Obligations</h2>
            <p>
              Unless otherwise agreed by both parties, user has following
              obligation:
            </p>
            <p>
              1. User shall take care of the Battery with the due care and shall
              follow Sling Mobility’s Battery User's Manual in Annexure 1. if
              user authorizes any third party to use the Battery, user shall
              ensure the third party complies with User's Manual in Annexure 1
              and the provisions of this Agreement.
            </p>
            <p>
              2. User may not disassemble or repair the Battery or any part of
              it by themselves or others, or take the Battery outside Sri Lanka.
            </p>
            <p>
              3. User shall endeavor to protect intellectual property rights of
              the Battery, and shall not conduct any acts that infringe or may
              infringe the intellectual property rights. The Sling Mobility App
              is owned and operated by Sling Mobility and/or its Technology
              partner. All content, visual interfaces, information, graphics,
              design, compilation, computer code, products, software, services,
              text, data, names, trade names, trademarks, trade dress, service
              marks, layout, logos, designs, images, graphics, illustrations,
              artwork, icons, photographs, displays, sound, music, video,
              animation, organization, assembly, arrangement, interfaces,
              databases, software, and/or technology and all intellectual
              property of any kind whatever and the selection and arrangement
              thereof (collectively, the “Sling Mobility Materials”) are owned
              exclusively by Sling Mobility or the licensors or suppliers of
              Sling Mobility and are protected by applicable copyright, trade
              dress, patent, and trademark laws, international conventions, and
              all other relevant intellectual property and proprietary rights,
              and applicable laws.
            </p>
            <p>
              4. As a User, you are granted a non-exclusive, non-transferable,
              revocable license to access and use the Sling Mobility App
              strictly in accordance with the terms outlined in this Agreement.
              You expressly agree that you will not use the Sling Mobility for
              any purpose that is unlawful or otherwise prohibited by this
              Agreement. This license does not grant you any ownership rights in
              any Sling Mobility Materials. No licenses, express or implied, to
              the intellectual property of Sling Mobility or its licensors are
              granted except as expressly authorized by this Agreement.
            </p>
            <p>
              5. You agree to respect and comply with all copyright and other
              proprietary notices, legends, or other restrictions contained in
              any Sling Mobility Materials, and you will not make any changes
              thereto. Without written authorization by Sling Mobility, you may
              not use, copy, display, distribute, modify, or reproduce any of
              the Sling Mobility Materials found on the Sling Mobility App.
              Establishing links to or from the Platform using Sling Mobility
              Materials is strictly prohibited unless approved in writing by
              Sling Mobility. All rights related to the Sling Mobility Materials
              are hereby reserved.
            </p>
            <p>
              6. You acknowledge that the Sling Mobility Materials may not be
              copied, reproduced, distributed, republished, displayed, posted,
              or transmitted in any form or by any means, including electronic,
              mechanical, photocopying, recording, or otherwise, without the
              express prior written consent of Sling Mobility. The Sling
              Mobility Materials are and shall remain the property of Sling
              Mobility. You may not modify, participate in the sale or transfer
              of, or create derivative works based on any Sling Mobility
              Materials in whole or in part.
            </p>
            <p>
              7. You are explicitly prohibited from modifying, publishing,
              transmitting, reverse engineering, participating in the transfer
              or sale, creating derivative works, or in any way exploiting any
              of the content and/or Sling Mobility Materials, in whole or in
              part, found on the Platform. Sling Mobility Materials and/or
              content is not for resale. You will not claim any ownership right
              in any material, software, or intellectual property displayed on,
              published by, or otherwise available through the Sling Mobility
              Platform.
            </p>
            <p>
              8. Where the user become aware of any claims being made in
              connection with the Battery or any part of it, user shall
              immediately notify Sling Mobility through the communication
              provided in the App etc.
            </p>
            <p>
              9. The user is required to perform a Battery swap at least once
              every 30 days. Should the Sling rider app dashboard display a
              "zero grid" level, the User must initiate a Battery swap within 24
              hours. In the event of a violation of this obligation, the user
              shall be held responsible for any damages in accordance with the
              terms of this Agreement.
            </p>
          </section>

          <section className="terms-section">
            <h2>8. Termination of this Agreement</h2>
            <p>
              1. In the occurrence of any of the following events, Sling
              Mobility shall promptly inform the user about the circumstances
              met. Depending on the severity of the situation, Sling Mobility
              reserves the right to suspend battery services, reduce the service
              term, partially revoke the rights and privileges granted under
              this Agreement, or terminate this Agreement:
            </p>
            <ol>i. User violates Article 6 of this Agreement;</ol>
            <ol>
              ii. User violates any contract or Agreement with Sling Mobility,
              or fails to pay the full invoiced amount on the due date;
            </ol>
            <ol>
              iii. the Electric Vehicle is lost, disabled, damaged, or has been
              stolen, detained;
            </ol>
            <ol>
              iv. the Battery is lost, stolen, disabled, damaged, or has been
              stolen, detained;
            </ol>
            <ol>
              v. Sufficient evidence proves that users use the Electric Vehicles
              or this Agreement to engage in violation of laws and regulations
              of Sri Lanka;
            </ol>
            <ol>
              vi. The Electric Vehicle is the subject matter of any legal
              proceedings; or
            </ol>
            <ol>
              vii. Any Other circumstances that Sling Mobility reasonably deem
              to be attributable to the user, and it is necessary for Sling
              Mobility to suspend the battery service, shorten the service term,
              cancel a part of the rights and interests of this Agreement or
              terminate this Agreement.
            </ol>
            <p>
              2. If user suffers any loss or damage due to the measures taken by
              Sling Mobility according to clause 8.1 above, Sling Mobility shall
              not be responsible for such losses or damages.
            </p>
            <p>
              3. In the event of Sling Mobility ceasing its operations for any
              reason, this Agreement shall be deemed terminated automatically.
              Notwithstanding the termination, the User shall have the option to
              acquire ownership of the battery presently in their possession. To
              exercise this option, the User agrees to pay the value of the
              battery, as determined by Sling Mobility at the time of closure.
              Upon receipt of the payment, Sling Mobility shall transfer
              ownership of the specified battery to the User.
            </p>
          </section>

          <section className="terms-section">
            <h2>9. Disclaimer</h2>
            <p>
              1. Sling Mobility only provides battery service based on the
              function and current status of the Battery. To the maximum extent
              permitted by law, Sling Mobility explicitly excludes any
              statements, guarantees and commitments, including but not limited
              to any statements, guarantees and commitments related to
              correctness, reliability, satisfaction, quality, suitability for
              specific purposes.
            </p>
            <p>
              2. Sling Mobility shall not be liable for damages caused by the
              battery service, unless due to any intentional act or
              omission/gross negligence attributable to Sling Mobility.
            </p>
          </section>

          <section className="terms-section">
            <h2>10. Amendment to this Agreement</h2>
            <p>
              1. User understands and agrees that Sling Mobility may modify or
              change the content of this Agreement (including transferring this
              Agreement to its affiliates in accordance with Article 14.2),
              however, Sling Mobility will notify every user 30 days before the
              effective date of such change of any terms and conditions (either
              individually via email or by public announcement on the App).
            </p>
            <p>
              2. The User agrees to execute, sign, or affix their electronic
              signature using electronic tools or methods. Documents executed,
              signed, or affixed with an electronic signature shall be deemed
              original and have the same legal effect as if executed, signed, or
              sealed in paper form. This Agreement, along with its attachments
              and any amendments, additions, changes, or terminations thereof,
              may also be conducted through electronic documents, with their
              legal effects being equivalent to those in paper form. By clicking
              the "I Agree" button, the User acknowledges and agrees that such
              action constitutes a valid and legally binding electronic
              signature. The User further agrees that their electronic
              acceptance shall have the same legal force and effect as if they
              had manually signed a paper version of this Agreement. The
              formation of this Agreement will be complete upon the electronic
              acceptance by the User.
            </p>
          </section>
          <section className="terms-section">
            <h2>11. Compensation </h2>
            <p>
              1. For any reason, including but not limited to the user's
              violation of the provisions of this Agreement, in case the Battery
              is damaged, stolen or detained, user shall immediately notify
              Sling Mobility. The user shall be liable to compensate Sling
              Mobility based on the value of the battery at the time of such
              event occurring as determined by Sling Mobility, and it is
              explicitly agreed that the Battery shall remain the property of
              Sling Mobility after the user compensates.
            </p>
            <p>
              2. If user dismantles the Battery by oneself or others, user will
              be liable under the law and legal proceedings may be instituted by
              Sling Mobility against the User in addition to claiming
              compensation as provided in clause 11.1. above
            </p>
            <p>
              3. If Sling Mobility is required to conduct any additional testing
              or dispose the Battery due to user violating any provisions of
              this Agreement, the related costs (including but not limited to
              manual operation costs, logistics costs, functional testing costs,
              etc.) shall be borne by the user.
            </p>
          </section>
          <section className="terms-section">
            <h2>12. Non Disclosure Agreement / Confidentiality Clause</h2>
            <p>12.1 Definition of Confidential Information: </p>
            <p>
              For the purpose of this Agreement, "Confidential Information"
              refers to any and all proprietary or confidential information
              disclosed by the Disclosing Party to the Receiving Party, whether
              orally, in writing, or through any other medium, including but not
              limited to business plans, strategies, financial data, technical
              data, trade secrets, intellectual property, or other sensitive
              information.
            </p>
            <p>12.2 Obligations of Confidentiality: </p>
            <p>
              The Receiving Party agrees to maintain the confidentiality of the
              Confidential Information and shall not disclose, reproduce, or
              otherwise use any such Confidential Information for any purpose
              other than as expressly permitted in writing by the Disclosing
              Party.
            </p>
            <p>12.3 Exclusions from Confidential Information: </p>
            <p>Confidential Information does not include information that:</p>
            <ol>
              a. is or becomes publicly available through no fault of the
              receiving party;
            </ol>
            <ol>
              b. was known to the Receiving Party prior to the disclosure by the
              Disclosing Party;
            </ol>
            <ol>
              c. is disclosed to the Receiving Party by a third party who is not
              bound by any confidentiality obligation;
            </ol>
            <ol>
              d. is independently developed by the Receiving Party without
              reference to or reliance upon the Confidential Information.
            </ol>
            <p>12.4 Duration of Obligation: </p>
            <p>
              The confidentiality obligations under this Agreement shall remain
              in effect for Two (02) years from the date of disclosure of the
              Confidential Information, or until the Confidential Information no
              longer qualifies as confidential under applicable law, whichever
              occurs first.
            </p>
            <p>12.5 Return or Destruction of Confidential Information: </p>
            <p>
              The confidentiality obligations under this Agreement shall remain
              in effect for Two (02) years from the date of disclosure of the
              Confidential Information, or until the Confidential Information no
              longer qualifies as confidential under applicable law, whichever
              occurs first.
            </p>

            <p>12.6 No License or Ownership Rights: </p>
            <p>
              Nothing in this Agreement shall be construed as granting the
              Receiving Party any rights, license, or ownership interest in the
              Confidential Information.
            </p>
          </section>
          <section className="terms-section">
            <h2>13. Governing Laws and Jurisdiction</h2>
            <p>
              This Agreement shall be governed by and construed in accordance
              with the laws of the Sri Lanka. In the event of any dispute
              arising out of or in connection with this Agreement, the Parties
              agree to refer any such dispute to a competent court in Sri Lanka
              for resolution.
            </p>
          </section>
          <section className="terms-section">
            <h2>14. Notification</h2>
            <p>
              1. Unless otherwise agreed by both parties, any notice made by
              Sling Mobility according to this Agreement through the Sling
              Mobility App. Unless user notify of the change by ways permitted
              by Sling Mobility, the notification method is limited to the
              address or e-mail provided by the user.
            </p>
            <p>
              2. User understands and agrees that, unless otherwise agreed by
              both parties, Sling Mobility may send an electronic Invoice for
              the service fee by e-mail without giving a written invoice. User
              understands and agrees that, in any of the following events, once
              Sling Mobility sends the electronic invoice, it shall be deemed to
              be delivered, and if the user suffers any loss or damage, Sling
              Mobility will not be liable for any such losses or damages:
            </p>
            <ol>
              i. The email fails to delivered or delays the delivery due to the
              insufficient space of the user's email mailbox;
            </ol>
            <ol>
              ii. Any failure to deliver, delay in delivery, to User’s email box
              due to the email being classified as spam mail or advertising mail
              box due to the setting of the company which provides the user's
              email mailbox service;
            </ol>
            <ol>
              iii. Failure to or delays in delivery of the email due to an
              abnormality cause by the company that provides user email service
              to Sling Mobility;
            </ol>
            <ol>iv. Other reasons not attributable to Sling Mobility.</ol>
          </section>
          <section className="terms-section">
            <h2>15. Other Matters</h2>
            <p>
              1. User understands and agrees that this Agreement does not
              include all the content and restrictions of the tariff plan. In
              case not mentioned in this Agreement, the user agrees to follow
              the subscription documents or other documents that specify the
              content and restrictions of the tariff plan.
            </p>
            <p>
              2. User understands and agrees that without the prior written
              consent of Sling Mobility, user may not transfer part or all of
              its rights or obligations under this Agreement to a third party.
              However, Sling Mobility may transfer all or part of this Agreement
              to its affiliates.
            </p>
            <p>
              3. If any or several terms of this Agreement are considered
              invalid, illegal, or unenforceable, the invalid, illegal or
              unenforceable situation shall not affect other terms of this
              Agreement.
            </p>
            <p>
              4. This Agreement (together with the Bike Rental Service Agreement
              for Fleet management and Logistics Services, where applicable) is
              the only and complete Agreement between the two parties in
              relation to the battery service, and its effect prevails any
              previous oral or written agreement
            </p>
          </section>
        </div>
      </div>
    </>
  );
}

export default TermsAndConditions;
